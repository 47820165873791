<template>
  <div class="mx-auto" style="max-width: 800px">
    <TButton
      content="Subscribe"
      @click="showModalSubscribe = true"
      icon="cil-bell"
      class="mb-3"
    />
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="helper.notification_subscribers"
      resource=""
      reloadable
      @click-reload="refresh"
      class="table-custom"
      removable
      noFilter
    >
      <template #support_type_id="{ item }">
        <td>
          <TMessageText
            :value="
              item.support_type ? item.support_type.name : item.support_type_id
            "
          />
        </td>
      </template>
    </TTableAdvance>
    <SubscribeModal :show.sync="showModalSubscribe" @updated="refresh" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubscribeModal from "./SubscribeModal.vue";

export default {
  components: { SubscribeModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px", _classes: "" },
        {
          key: "support_type_id",
          label: "Support type",
          _classes: "text-nowrap",
        },
      ],
      showModalSubscribe: false,
    };
  },
  created() {
    this.$store.dispatch("helper.notification_subscribers.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "helper.notification_subscribers.list",
      detail: "helper.notification_subscribers.detail",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("helper.notification_subscribers.fetch");
    },
  },
};
</script>
