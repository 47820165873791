<template>
  <TModal
    title="Subscribe notification"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
  >
    <template #actions>
      <TButton
        @click="onSubscribe"
        content="Subscribe"
        icon="cil-bell"
        :disabled="loading"
      />
    </template>
    <SSelectSupportTypes
      :prepend="[]"
      label="Support type"
      :value.sync="support_type"
    />
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      support_type: "",
    };
  },
  computed: {
    ...mapGetters({
      loading: "helper.notification_subscribers.loading",
    }),
  },
  methods: {
    onSubscribe() {
      this.$store
        .dispatch("helper.notification_subscribers.create", {
          support_type_id: this.support_type,
        })
        .then(() => {
          this.support_type = "";
          this.$emit("update:show", false);
          this.$emit("updated");
        });
    },
  },
};
</script>
